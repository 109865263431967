export type SearchStore = {
  search: string;
  open: boolean;
  mobileOpen: boolean;
};

export const searchStore = reactive<SearchStore>({
  search: "",
  open: false,
  mobileOpen: false,
});
