import { useTokenCookies } from "./useTokenCookies";

export const useUserJwtClaims = () => {
  const { accessTokenCookie, refreshTokenCookie } = useTokenCookies();

  const jwtClaims = computed<SaleorJwtClaims | undefined>(() => {
    const accessToken = accessTokenCookie.value;

    if (accessToken) {
      return parseJwt(accessToken);
    }

    const refreshToken = refreshTokenCookie.value;

    if (refreshToken) {
      return parseJwt(refreshToken);
    }
  });

  return jwtClaims;
};
