<template>
  <ToastRoot
    v-for="(toast, i) in toasts"
    :key="i"
    :duration="toast.duration ?? DEFAULT_TOAST_DURATION"
    :open="toValue(toast.open)"
    class="relative grid grid-cols-[auto_max-content] items-center gap-x-[15px] overflow-hidden rounded-md bg-white p-[15px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] [grid-template-areas:_'title_action'_'description_action'] data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:animate-hide data-[state=open]:animate-slideIn data-[swipe=end]:animate-swipeOut data-[swipe=cancel]:transition-[transform_200ms_ease-out]"
    :style="{ '--toast-duration': `${toast.duration ?? DEFAULT_TOAST_DURATION}ms` }"
  >
    <ToastClose class="absolute right-1 top-1 p-2">
      <Icon icon="radix-icons:cross-2" />
    </ToastClose>

    <div class="flex items-center gap-4">
      <Icon :icon="iconMap[toast.type]" :class="colorMap[toast.type]" />
      <div class="flex flex-col gap-1">
        <ToastTitle class="text-[15px] font-medium [grid-area:_title]">
          {{ toast.title }}
        </ToastTitle>
        <ToastDescription v-if="toast.description" as-child>
          <slot name="description">
            {{ toast.description }}
          </slot>
        </ToastDescription>
      </div>
    </div>

    <div
      class="animate-shrink absolute bottom-0 left-0 right-0 h-1"
      :class="bgColorMap[toast.type]"
    />
    <ToastAction
      v-if="toast.action"
      class="[grid-area:_action]"
      as-child
      :alt-text="toast.action.altText"
      @click="toast.action.onClick"
    >
      <button
        class="inline-flex h-[25px] items-center justify-center rounded bg-primary-lightest px-[10px] text-xs font-medium leading-[25px] text-primary shadow-[inset_0_0_0_1px] shadow-primary-light hover:shadow-[inset_0_0_0_1px] hover:shadow-primary-light focus:shadow-[0_0_0_2px] focus:shadow-focus"
      >
        {{ toast.action.buttonText }}
      </button>
    </ToastAction>
  </ToastRoot>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { ToastAction, ToastClose, ToastDescription, ToastRoot, ToastTitle } from "radix-vue";

import { DEFAULT_TOAST_DURATION } from "~/constants";
import type { Toast } from "~/stores/toast";
import { toastStore } from "~/stores/toast";

const { toasts } = toRefs(toastStore);

const iconMap: Record<Toast["type"], string> = {
  success: "radix-icons:check-circled",
  error: "radix-icons:cross-2",
  info: "radix-icons:info-circled",
  warning: "radix-icons:exclamation-triangle",
};

const colorMap: Record<Toast["type"], string> = {
  success: "text-green-500",
  error: "text-red-500",
  info: "text-blue-500",
  warning: "text-yellow-600",
};

const bgColorMap: Record<Toast["type"], string> = {
  success: "bg-green-500",
  error: "bg-error",
  info: "bg-primary",
  warning: "bg-yellow-600",
};
</script>

<style scoped>
@keyframes shrink {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.animate-shrink {
  animation: shrink var(--toast-duration) linear;
}
</style>
