<template>
  <ToastProvider>
    <TooltipProvider>
      <Header :key="languageCode" :navigation="data?.navigation" />
      <MainMenu :navigation="data?.navigation" class="sticky top-16 min-h-[49px]" />
      <div class="flex min-h-[calc(100dvh-105px)] scroll-pt-[105px] flex-col">
        <main
          class="md:mb-18 m-auto mb-6 flex w-full max-w-7xl flex-1 flex-col items-center justify-center gap-6 px-3 py-3 sm:mb-12 md:px-6"
        >
          <h1 v-if="error?.statusCode === 404" class="mt-4 text-center">
            {{ errorMessage }}
          </h1>
          <NuxtLinkLocale href="/" class="as-h3 rounded-lg border-2 border-primary px-3 py-2">
            {{ $t("error.backToHome") }}
          </NuxtLinkLocale>
          {{ error.message }}
        </main>
        <Footer :key="languageCode" :footer="data?.footer" />
      </div>

      <ToastViewport
        class="fixed bottom-0 right-0 z-[2147483647] m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]"
      />
    </TooltipProvider>
  </ToastProvider>
</template>
<script setup lang="ts">
import { ToastProvider, ToastViewport, TooltipProvider } from "radix-vue";

import type { NuxtError } from "#app";

import { DefaultLayoutDocument } from "./src/api/generated/graphql";
import { useChannel } from "./src/channel/composables";

const { error } = defineProps<{
  error: NuxtError;
}>();

const { t } = useI18n();
const { languageCode } = useChannel();

const { result: data } = useQuery(
  DefaultLayoutDocument,
  computed(() => ({
    languageCode: languageCode.value,
  }))
);

const errorMessage = computed(() => {
  if (error.statusCode === 404) {
    return t("error.404");
  }

  return t("error.somethingWentWrong");
});
</script>
