<template>
  <div class="max-w-[380px] rounded-bl-lg rounded-br-lg border bg-white">
    <ul v-if="checkout?.lines.length" class="mb-2 max-h-[40vh] overflow-y-auto px-5 pb-0 pt-5">
      <li
        v-for="line in checkout.lines"
        :key="line.id"
        class="flex justify-between gap-16 py-3 [&:not(:last-child)]:border-b"
      >
        <div class="">
          <p class="label line-clamp-2">{{ line.variant.product.name }}</p>
          <span class="text-12">
            {{ line.variant.product.slug }}
          </span>
        </div>
        <div class="flex flex-col items-end justify-between">
          <span class="label">{{ formatCurrency(line.totalPrice.gross) }}</span>
          <button
            class="-mr-2 rounded-lg p-3 text-error transition-all hover:bg-error/10"
            @click="$emit('delete-line', line.id)"
          >
            <Icon icon="lucide:trash-2" />
          </button>
        </div>
      </li>
    </ul>
    <!-- <Separator /> -->
    <div v-else class="py-6">
      <p class="text-center">
        {{ $t("order.cart.empty") }}
      </p>
    </div>
    <div v-if="checkout?.lines.length" class="sticky bottom-0 border-t bg-white p-5">
      <div class="flex justify-between">
        <p>
          <span class="label">{{ $t("order.subtotal") }}</span>
          <span class="light"> (excl. taxes) </span>
        </p>
        <div class="flex flex-col items-end">
          <span>
            {{ formatCurrency(checkout.totalPrice.gross) }}
          </span>
        </div>
      </div>
      <NuxtLinkLocale
        :href="`/checkout?checkoutId=${checkout?.id}`"
        class="label mt-6 inline-block w-full rounded-lg bg-primary p-2.5 text-center text-white disabled:cursor-not-allowed disabled:bg-disabled-light disabled:text-lighter"
        disabled
      >
        {{ $t("order.goToCheckout") }}
      </NuxtLinkLocale>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { CartPreviewQuery } from "@graphql";
import { Icon } from "@iconify/vue";

export type checkoutItem = {
  id: string;
  title: string;
  subtitle?: string;
  total: {
    currency: string;
    amount: number;
  };
};

const props = defineProps<{
  checkout: CartPreviewQuery["checkout"];
}>();

const checkout = computed(() => props.checkout);

defineEmits<{
  "delete-line": [id: string];
}>();

const { formatCurrency } = useFormatCurrency();
</script>
