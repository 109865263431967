<template>
  <header class="sticky top-0 z-20 min-h-[56px] border-b bg-white backdrop-blur-md sm:border-none">
    <PageContent>
      <div class="flex h-16 items-center justify-between gap-4 md:gap-8">
        <div class="flex flex-grow items-center gap-4 sm:gap-8 md:gap-16">
          <NuxtLinkLocale href="/" aria-label="Genotic main page">
            <NuxtImg
              src="/logo.svg"
              :width="88"
              :height="35"
              alt="Genotic logo"
              class="h-10 min-w-[88px]"
            />
          </NuxtLinkLocale>
          <GlobalSearch class="hidden w-full sm:flex" />
        </div>

        <div class="flex items-center gap-1">
          <LangSelect class="hidden sm:flex" />
          <Separator orientation="vertical" decorative />
          <NuxtLinkLocale
            v-if="isAuthenticated"
            to="/profile"
            class="flex items-center justify-center gap-2 text-nowrap rounded px-3 py-2 hover:bg-gray1"
          >
            <Icon icon="lucide:user" class="inline-block" :ssr="true" />
            <span class="hidden md:inline">
              {{ $t("header.myAccount") }}
            </span>
          </NuxtLinkLocale>

          <CartOverlay>
            <template #trigger="{ itemsCount, checkoutId }">
              <NuxtLinkLocale
                :to="`/cart?checkoutId=${checkoutId}`"
                class="relative flex items-center justify-center gap-2 text-nowrap rounded px-4 py-2 hover:bg-gray1"
              >
                <NuxtImg src="/icons/cart.svg" :width="20" :height="20" class="icon" />

                <span class="hidden md:inline">
                  {{ $t("header.cart") }}
                </span>

                <span v-if="itemsCount">({{ itemsCount }})</span>
              </NuxtLinkLocale>
            </template>
          </CartOverlay>
          <button
            v-if="isAuthenticated"
            :aria-label="$t('header.signOut')"
            class="hidden text-nowrap rounded p-2 hover:bg-gray1 sm:block"
            @click="() => signOut()"
          >
            {{ $t("auth.logout") }}
          </button>
          <NuxtLinkLocale
            v-else
            to="/login"
            class="hidden text-nowrap rounded px-3 py-2 hover:bg-gray1 sm:inline-block"
          >
            {{ $t("auth.login") }}
          </NuxtLinkLocale>
          <button
            :aria-label="$t('header.search')"
            class="rounded px-3 py-2 hover:bg-gray1 sm:hidden"
            @click="searchStore.mobileOpen = !searchStore.mobileOpen"
          >
            <Icon icon="radix-icons:magnifying-glass" :ssr="true" />
          </button>
          <button
            class="rounded px-3 py-2 hover:bg-gray1 sm:hidden"
            :aria-label="$t('header.menu')"
            @click="searchStore.open = !searchStore.open"
          >
            <Icon icon="radix-icons:hamburger-menu" :ssr="true" />
          </button>
          <MobileNavigationOverlay :navigation="navigation" />
          <MobileSearchOverlay />
        </div>
      </div>
    </PageContent>
  </header>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";

import { useSignOut } from "~/src/auth/composables/useSignOut";
import { CartOverlay } from "~/src/cart/components";
import { searchStore } from "~/stores/search";

import type { Navigation } from "../menu/MainMenu.vue";
import MobileNavigationOverlay from "../menu/MobileNavigationOverlay.vue";
import MobileSearchOverlay from "../menu/MobileSearchOverlay.vue";

export type HeaderProps = {
  navigation: Navigation;
};

defineProps<HeaderProps>();

const { signOut, isAuthenticated } = useSignOut();
</script>
