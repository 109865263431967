<template>
  <MobileOverlayRoot v-model:open="mobileOpen">
    <search class="p-3">
      <form>
        <fieldset>
          <legend class="sr-only">{{ $t("common.search") }}</legend>
          <label for="search" class="light">{{ $t("common.search") }}</label>
          <input
            id="search"
            :value="searchParams.search"
            :placeholder="$t('common.search')"
            class="block w-full rounded-lg border-2 border-gray3 px-3 py-2 text-sm text-text-main focus:border-2 focus:border-focus focus:outline-none focus:ring-focus"
            required
            @input="onSearch"
          />
        </fieldset>
      </form>
      <template v-if="data?.length">
        <Separator class="my-4 bg-gray4" />
        <p class="mb-3">{{ $t("search.searchResultsInIndividualCategories") }}</p>
        <ul class="flex flex-col gap-3">
          <Result
            v-for="result in data"
            :key="result.id"
            :category="result.name"
            :href="`/categories/${result.slug}?search=${searchParams.search}`"
            class="!rounded-lg bg-white text-text-main"
            :count="result.productsCount"
            :loading="loading"
            @click="mobileOpen = false"
          />
        </ul>
      </template>
    </search>
  </MobileOverlayRoot>
</template>
<script setup lang="ts">
import { useGlobalSearch } from "~/src/search/composables/useGlobalSearch";
import { searchStore } from "~/stores/search";
import { Breakpoints } from "~/utils/mobile";

import Result from "../ui/GlobalSearch/Result.vue";
import MobileOverlayRoot from "./MobileOverlayRoot.vue";

const searchParams = useUrlSearchParams<{ search?: string }>();

const mobileOpen = toRef(searchStore, "mobileOpen");
const open = toRef(searchStore, "open");
const search = toRef(searchStore, "search");
const searchParamsSearch = toRef(searchParams, "search");

syncRef(search, searchParamsSearch, {
  transform: {
    rtl: (right) => right ?? "",
  },
});

watch(mobileOpen, (open) => {
  if (open) {
    onSearch(search.value);
  }
});

if (typeof document !== "undefined") {
  useResizeObserver(document.body, (entries) => {
    if (entries[0].contentRect.width >= Breakpoints.sm && mobileOpen.value) {
      mobileOpen.value = false;
      open.value = true;
    }
  });
}

const { search: _search, data, debouncedSearch, loading } = useGlobalSearch();

const onSearch = (e: Event | string) => {
  const input = typeof e === "string" ? e : (e.target as HTMLInputElement).value;
  search.value = input;
  debouncedSearch(input);
};

onMounted(() => {
  // NOTE: workaround when user navigates with search params
  if (searchParams.search?.length && !data.value?.length) {
    _search(searchParams.search);
  }
});
</script>
