export const useSignOut = () => {
  const { signOut: _signOut, isAuthenticated } = useAuth();

  const localePath = useLocalePath();
  const signOut = async (redirect?: string) => {
    _signOut();
    await navigateTo(localePath(redirect ?? "/"));
  };

  return {
    isAuthenticated,
    signOut,
  };
};
