<template>
  <ToastProvider>
    <TooltipProvider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <ToastViewport
        class="fixed bottom-0 right-0 z-[2147483647] m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]"
      />
    </TooltipProvider>
    <GlobalToast />
  </ToastProvider>
</template>
<script setup lang="ts">
import { ToastProvider, ToastViewport, TooltipProvider } from "radix-vue";

import GlobalToast from "~/components/molecules/GlobalTost.vue";

const config = useRuntimeConfig();

const { locale } = useI18n();

useHead({
  script: [
    {
      async: true,
      src: `https://www.googletagmanager.com/gtag/js?id=${config.public.GOOGLE_TAG_MANAGER_ID}`,
    },
  ],
  htmlAttrs: {
    lang: locale.value,
  },
});
</script>
