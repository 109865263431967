export const rename = async (oldName: string, newName: string): Promise<boolean> => {
  const oldCookieValue = get(oldName);
  if (!oldCookieValue) {
    return false;
  }

  if (!oldCookieValue) {
    return false;
  }

  set(newName, oldCookieValue);
  remove(oldName);

  return true;
};

export const get = (name: string): string | undefined => {
  const cookie = document.cookie.split("; ").find((cookie) => cookie.startsWith(`${name}=`));
  if (!cookie) {
    return undefined;
  }

  const str = cookie.split(`${name}=`)[1];
  if (!str) {
    return undefined;
  }

  return cookie.split("=")[1];
};

export const set = (name: string, value: string, expires?: Date): void => {
  if (expires) {
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/;`;
  } else {
    document.cookie = `${name}=${value}; path=/;`;
  }
};

export const remove = (name: string): void => {
  set(name, "", new Date(0));
};
