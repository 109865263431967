import type { FunctionWithClient } from "../types";
import { create, find } from ".";

type FindOrCreateParams = FunctionWithClient<{
  checkoutId: string | null | undefined;
  channel: string;
}>;

export const findOrCreate = async ({ client, variables }: FindOrCreateParams) => {
  if (!variables.checkoutId) {
    const res = await create({
      client,
      variables: {
        channel: variables.channel,
        lines: [],
      },
    });

    return res;
  }

  const checkout = await find({
    client,
    variables: {
      checkoutId: variables.checkoutId,
    },
  });

  if (checkout.success) {
    return checkout;
  }

  return await create({
    client,
    variables: {
      channel: variables.channel,
      lines: [],
    },
  });
};
