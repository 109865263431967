import type { ApolloClient } from "@apollo/client/core";
import { ApolloLink, concat, HttpLink } from "@apollo/client/core";
import { fromPromise } from "@apollo/client/link/utils/fromPromise";
import type { TokenRefreshMutation, TokenRefreshMutationVariables } from "@graphql";
import { provideApolloClient } from "@vue/apollo-composable";

import { useTokenCookies } from "~/composables/auth";

export default defineNuxtPlugin(({ hook }) => {
  const { clients } = useApollo();

  const defaultClient: ApolloClient<unknown> = clients!.default;

  const { accessTokenCookie, refreshTokenCookie } = useTokenCookies();
  const config = useRuntimeConfig();

  const refreshToken = async (): Promise<string | undefined> => {
    const res = await fetchGql<TokenRefreshMutation, TokenRefreshMutationVariables>({
      query: `
          mutation TokenRefresh($csrfToken: String, $refreshToken: String) {
            tokenRefresh(csrfToken: $csrfToken, refreshToken: $refreshToken) {
              token
              errors {
                code
                field
                message
              }
            }
          }
        `,
      variables: {
        refreshToken: refreshTokenCookie.value,
      },
    });

    if (res.data.tokenRefresh?.token) {
      return res.data.tokenRefresh.token;
    }

    return undefined;
  };

  const refreshTokenLink = new ApolloLink((operation, forward) => {
    if (accessTokenCookie.value && tryGetExpFromJwt(accessTokenCookie.value)) {
      return forward(operation);
    }

    if (!refreshTokenCookie.value) {
      return forward(operation);
    }

    return fromPromise(
      refreshToken().then((token) => {
        if (token) {
          accessTokenCookie.value = token;
        }
      })
    ).flatMap(() => forward(operation));
  });

  const authLink = new ApolloLink((operation, forward) => {
    const token = accessTokenCookie.value;

    operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    }));

    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: config.public.SALEOR_API_URL,
    credentials: "include",

    headers: {
      Authorization: `Bearer ${accessTokenCookie.value}`,
    },
  });

  defaultClient.setLink(concat(refreshTokenLink, concat(authLink, httpLink)));

  provideApolloClient(defaultClient);

  // eslint-disable-next-line no-console
  hook("apollo:error", console.error);
});
