import type { CheckoutCartFragment, CheckoutError } from "@graphql";
import { CheckoutAddLineDocument, CheckoutCartFragmentDoc } from "@graphql";

import type { FunctionWithClient } from "../types";
import { findOrCreate } from "./findOrCreate";

type AddLineParams = FunctionWithClient<{
  checkoutId: string | null | undefined;
  productVariantId: string;
  quantity: number;
  channel: string;
}>;

export const addLine = async ({
  client,
  variables,
}: AddLineParams): Promise<Result<CheckoutError[] | string, CheckoutCartFragment>> => {
  const checkout = await findOrCreate({
    client,
    variables: {
      checkoutId: variables.checkoutId,
      channel: variables.channel,
    },
  });

  if (!checkout.success) {
    return checkout;
  }

  let currentCheckout: CheckoutCartFragment | null = null;

  try {
    currentCheckout = client.readFragment({
      id: `Checkout:${checkout.value.id}`,
      fragment: CheckoutCartFragmentDoc,
      fragmentName: "CheckoutCart",
    });
    // eslint-disable-next-line
  } catch {}

  const getLines = () => {
    const line = currentCheckout?.lines.find(
      (line) => line.variant.id === variables.productVariantId
    );

    if (line) {
      return currentCheckout?.lines.map((line) => {
        if (line.variant.id === variables.productVariantId) {
          return {
            ...line,
            quantity: line.quantity + variables.quantity,
            totalPrice: {
              ...line.totalPrice,
              gross: {
                ...line.totalPrice.gross,
                amount:
                  line.totalPrice.gross.amount +
                  variables.quantity * (line.variant.pricing?.price?.gross.amount ?? 0),
              },
            },
          };
        }
      });
    }

    return [
      ...(currentCheckout?.lines ?? []),
      {
        id: `${checkout.value.id}-${variables.productVariantId}-${variables.quantity}`,
        quantity: variables.quantity,
      },
    ];
  };

  const optimisticCheckout = {
    ...currentCheckout,
    lines: getLines(),
  };

  const res = await client.mutate({
    mutation: CheckoutAddLineDocument,
    variables: {
      id: checkout.value.id,
      lines: [
        {
          variantId: variables.productVariantId,
          quantity: variables.quantity,
        },
      ],
    },
    optimisticResponse: {
      checkoutLinesAdd: {
        __typename: "CheckoutLinesAdd",
        checkout: optimisticCheckout as unknown as CheckoutCartFragment,
        errors: [],
      },
    },
    update: (cache, { data }) => {
      if (data?.checkoutLinesAdd?.checkout) {
        cache.writeFragment({
          id: `Checkout:${checkout.value.id}`,
          fragment: CheckoutCartFragmentDoc,
          data: data.checkoutLinesAdd.checkout,
        });
      }
    },
  });

  const error = res.data?.checkoutLinesAdd?.errors;

  if (error?.length) {
    return err(error);
  }

  if (!res.data?.checkoutLinesAdd?.checkout) {
    return err("Checkout not found");
  }

  return ok(res.data.checkoutLinesAdd.checkout);
};
