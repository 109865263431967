import type { AccountError } from "@graphql";
import { RegisterUserDocument, TokenCreateDocument } from "@graphql";
import type { LocaleObject } from "@nuxtjs/i18n";

import { getCheckoutCookieName } from "~/src/checkout/utils";
import type { RegisterUserSchema, SignInSchema } from "~/src/user/schemas";
import * as cookie from "~/utils/cookie";

import { useTokenCookies } from "./auth";

export const useAuth = (channel?: MaybeRefOrGetter<LocaleObject["channel"]>) => {
  const config = useRuntimeConfig();
  const { client } = useApolloClient();

  const { accessTokenCookie, refreshTokenCookie } = useTokenCookies();

  const signIn = async (credentials: SignInSchema): Promise<Result<AccountError[], null>> => {
    const res = await client.mutate({
      mutation: TokenCreateDocument,
      variables: credentials,
      fetchPolicy: "no-cache",
    });

    const errors = res.data?.tokenCreate?.errors;

    if (errors?.length) {
      return err(errors);
    }

    const accessToken = res.data?.tokenCreate?.token;
    const refreshToken = res.data?.tokenCreate?.refreshToken;

    const _channel = toValue(channel);
    if (accessToken && _channel) {
      accessTokenCookie.value = accessToken;
      const parsedJwt = parseJwt(accessToken);

      const oldCookieName = getCheckoutCookieName(_channel);
      const newCookieName = getCheckoutCookieName(_channel, parsedJwt?.user_id);
      const oldCookieValue = cookie.get(oldCookieName);

      if (oldCookieValue) {
        cookie.rename(oldCookieName, newCookieName);
        refreshCookie(oldCookieName);
        refreshCookie(newCookieName);
      }
    }

    if (refreshToken) {
      refreshTokenCookie.value = refreshToken;
    }
    return ok(null);
  };

  const register = async (
    credentials: Omit<RegisterUserSchema, "password2">
  ): Promise<Result<AccountError[], null>> => {
    const res = await client.mutate({
      mutation: RegisterUserDocument,
      variables: {
        input: {
          ...credentials,
          redirectUrl: `${config.public.STOREFRONT_URL}/confirm-email`,
        },
      },
      fetchPolicy: "no-cache",
    });

    const errors = res.data?.accountRegister?.errors;

    if (errors?.length) {
      return err(errors);
    }

    return ok(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const confirmAccount = async (): Promise<void> => {};

  const signOut = (): void => {
    accessTokenCookie.value = undefined;
    refreshTokenCookie.value = undefined;
  };

  const isAuthenticated = computed<boolean>(() => {
    const accessToken = accessTokenCookie.value;

    if (accessToken && tryGetExpFromJwt(accessToken)) {
      return true;
    }

    const refreshToken = refreshTokenCookie.value;

    if (refreshToken && tryGetExpFromJwt(refreshToken)) {
      return true;
    }

    return false;
  });

  const getJwtClaims = (tokenType: "access" | "refresh" = "access") => {
    if (tokenType === "access" && accessTokenCookie.value) {
      return parseJwt(accessTokenCookie.value);
    }

    if (tokenType === "refresh" && refreshTokenCookie.value) {
      return parseJwt(refreshTokenCookie.value);
    }
  };

  return { signIn, isAuthenticated, signOut, register, getJwtClaims };
};
