import type { ApolloClient } from "@apollo/client/core";

import { CheckoutDeleteLinesDocument } from "~/src/api/generated/graphql";

export const deleteLines = async (
  lineIds: string | string[],
  client: ApolloClient<unknown>,
  checkoutId: string | null | undefined
): Promise<void> => {
  if (!checkoutId) {
    throw new Error(ErrorType.NoCheckoutId);
  }

  await client.mutate({
    mutation: CheckoutDeleteLinesDocument,
    variables: {
      checkoutId,
      lineIds,
    },
  });
};
