import type { LocaleObject } from "@nuxtjs/i18n";

import type { CountryCode, LanguageCodeEnum } from "~/src/api/generated/graphql";

export type Channel = LocaleObject["channel"];

export const useChannel = () => {
  const { locale: currentLocale, locales } = useI18n();

  const channel = computed<Channel>(() => {
    const locale = locales.value.find(
      (locale) => locale.code === currentLocale.value
    )! as LocaleObject;

    return locale.channel;
  });

  const country = computed<CountryCode>(() => toCountryCode(channel.value));

  const languageCode = computed<LanguageCodeEnum>(() => toLanguageCode(currentLocale.value)!);

  return {
    channel,
    country,
    locale: currentLocale,
    languageCode,
  };
};
