import { CheckoutFindDocument, type CheckoutFindQuery } from "~/src/api/generated/graphql";

import type { FunctionWithClient } from "../types";

type FindParams = FunctionWithClient<{
  checkoutId: string | null | undefined;
}>;

export const find = async ({
  client,
  variables,
}: FindParams): Promise<Result<string, NonNullable<CheckoutFindQuery["checkout"]>>> => {
  if (!variables.checkoutId) {
    return {
      success: false,
      error: "No checkout ID provided",
    };
  }

  const { data } = await client.query({
    query: CheckoutFindDocument,
    variables: { id: variables.checkoutId, includeChannel: false },
  });

  if (!data.checkout) {
    return {
      success: false,
      error: "No checkout found",
    };
  }

  return {
    success: true,
    value: data.checkout,
  };
};
