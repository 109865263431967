import { DEFAULT_TOAST_DURATION } from "~/constants";

export const cartStore = reactive({
  open: false,
  timer: 0,

  /**
   * Opens the cart and sets a timer to automatically close it.
   * @param timeout The duration in milliseconds before the cart closes. Default is 3000 (DEFAULT_TOAST_DURATION).
   */
  setOpenWithTimeout(timeout: number = DEFAULT_TOAST_DURATION) {
    this.open = true;
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.open = false;
    }, timeout);
  },
});
