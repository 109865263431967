/** used for pagination */
export const INITIAL_PAGE_SIZE = 50 satisfies (typeof ITEMS_PER_PAGE_OPTIONS)[number];

/** used for pagination */
export const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 75, 100] as const;

/** 30 days, change if saleor backend settings change */
export const CHECKOUT_COOKIE_MAX_AGE = 60 * 60 * 24 * 30;

/** default toast duration */
export const DEFAULT_TOAST_DURATION = 3000;
