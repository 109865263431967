<template>
  <search class="relative flex max-w-[450px] items-center">
    <form class="relative w-full" @focusin="focused = true">
      <legend class="sr-only">{{ $t("common.search") }}</legend>
      <label for="search" class="sr-only">{{ $t("common.search") }}</label>
      <input
        id="search"
        :value="searchParams.search"
        type="search"
        class="block w-full rounded-lg border-2 border-gray3 px-3 py-2 text-sm focus:border-2 focus:border-primary focus:outline-none focus:ring-focus"
        :placeholder="$t('common.search')"
        required
        @input="onSearch"
      />
      <Icon icon="radix-icons:magnifying-glass" class="absolute right-2.5 top-2.5" />
    </form>
    <button
      class="ml-4 flex items-center gap-1"
      :class="{
        invisible: !resultsOpen,
      }"
      @click="focused = false"
    >
      <Icon icon="radix-icons:cross-2" />
      {{ $t("common.close") }}
    </button>
    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="resultsOpen"
          class="fixed inset-0 z-10 bg-black/50 backdrop-blur-xs"
          tabindex="0"
          role="button"
          :aria-label="$t('search.closeSearchResults')"
          @click="focused = false"
          @keydown.tab="focused = false"
        />
      </Transition>
      <Transition name="slide-fade">
        <div v-if="resultsOpen" class="fixed left-0 right-0 top-16 z-30 border-t bg-white py-5">
          <div class="m-auto max-w-7xl px-3 md:px-6">
            <span class="font-bold">{{ $t("search.searchResultsInIndividualCategories") }}</span>
            <ul
              v-if="data?.length"
              class="mt-3 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4"
            >
              <Result
                v-for="result in data"
                :key="result.id"
                :category="result.name"
                :href="`/categories/${result.slug}?search=${searchParams.search}`"
                :count="result.productsCount"
                :loading="loading"
                @click="focused = false"
              />
            </ul>
          </div>
        </div>
      </Transition>
    </Teleport>
  </search>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";

import { useGlobalSearch } from "~/src/search/composables/useGlobalSearch";
import { searchStore } from "~/stores/search";
import { Breakpoints } from "~/utils/mobile";

import Result from "./GlobalSearch/Result.vue";

const searchParams = useUrlSearchParams<{ search?: string }>();
const searchParamsSearch = toRef(searchParams, "search");

watch(searchStore, (newValue) => {
  searchParams.search = newValue.search;
});

const { search: _search, data, debouncedSearch, loading } = useGlobalSearch();

const focused = toRef(searchStore, "open");
const mobileOpen = toRef(searchStore, "mobileOpen");
const search = toRef(searchStore, "search");

syncRef(search, searchParamsSearch, {
  transform: {
    rtl: (right) => right ?? "",
  },
});

watch(focused, (focused) => {
  if (focused) {
    onSearch(search.value);
  }
});

const hasInput = computed(() => (searchParams.search?.length ?? 0) > 0);

const resultsOpen = computed(() => focused.value && hasInput.value);

const onSearch = (e: Event | string) => {
  const input = typeof e === "string" ? e : (e.target as HTMLInputElement).value;
  search.value = input;
  debouncedSearch(input);
};

if (typeof document !== "undefined") {
  useResizeObserver(document.body, (entries) => {
    if (entries[0].contentRect.width < Breakpoints.sm && focused.value) {
      focused.value = false;
      mobileOpen.value = true;
    }
  });
}

onMounted(() => {
  // NOTE: workaround when user navigates with search params
  if (searchParams.search?.length && !data.value?.length) {
    _search(searchParams.search);
  }
});
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
