export type Result<Error = unknown, Value = unknown> =
  | {
      success: false;
      error: Error;
    }
  | {
      success: true;
      value: Value;
    };

export function ok<TValue>(value: TValue): { success: true; value: TValue } {
  return {
    success: true,
    value,
  };
}

export function err<TError>(error: TError): { success: false; error: TError } {
  return {
    success: false,
    error,
  };
}
