<template>
  <Separator
    class="bg-gray3 data-[orientation=horizontal]:h-px data-[orientation=vertical]:h-full data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-px"
    v-bind="props"
  />
</template>
<script setup lang="ts">
import type { SeparatorProps } from "radix-vue";
import { Separator } from "radix-vue";

const props = defineProps<SeparatorProps>();
</script>
