import {
  CheckoutCreateDocument,
  type CheckoutCreateMutation,
  type CheckoutError,
  type CheckoutLineInput,
} from "@graphql";

import type { FunctionWithClient } from "../types";

type CreateVariables = FunctionWithClient<{
  channel: string;
  lines: CheckoutLineInput[];
}>;

export const create = async ({
  client,
  variables,
}: CreateVariables): Promise<
  Result<
    string | CheckoutError[],
    NonNullable<NonNullable<CheckoutCreateMutation["checkoutCreate"]>["checkout"]>
  >
> => {
  const { data } = await client.mutate({
    mutation: CheckoutCreateDocument,
    variables,
  });

  const errors = data?.checkoutCreate?.errors;

  if (errors?.length) {
    return err(errors);
  }

  if (!data?.checkoutCreate?.checkout) {
    return err("No checkout created");
  }

  return ok(data.checkoutCreate.checkout);
};
