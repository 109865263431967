import {
  type AddressInput,
  AddressTypeEnum,
  type CheckoutCartFragment,
  type CheckoutDetailsFragment,
  type CheckoutError,
} from "@graphql";
import {
  CheckoutBillingAddressUpdateDocument,
  CheckoutEmailUpdateDocument,
  CheckoutShippingAddressUpdateDocument,
} from "@graphql";

import { useUserJwtClaims } from "~/composables/auth";
import { CHECKOUT_COOKIE_MAX_AGE } from "~/constants";
import { useChannel } from "~/src/channel/composables";

import {
  addLine as addLineUtil,
  create as createUtil,
  deleteLines as deleteLinesUtil,
  find as findUtil,
  findOrCreate as findOrCreateUtil,
  getCheckoutCookieName,
} from "../utils";

type UseCheckoutOptions = {
  /**
   * by default it gets value from route.params.channel, but can be changed
   */
  channel?: string;
};

export const useCheckoutId = (options?: UseCheckoutOptions) => {
  const config = useRuntimeConfig();
  const { channel: currentChannel } = useChannel();
  const jwtClaims = useUserJwtClaims();

  const cookieName = computed(() =>
    getCheckoutCookieName(options?.channel ?? currentChannel.value, jwtClaims.value?.user_id)
  );

  const checkoutId = useCookie(cookieName.value, {
    sameSite: "lax",
    secure: config.public.STOREFRONT_URL.startsWith("https"),
    maxAge: CHECKOUT_COOKIE_MAX_AGE,
  });

  return checkoutId;
};

/*
 * Completing checkout
 * {@link https://docs.saleor.io/developer/checkout/lifecycle#completing-checkout}
 */
export const useCheckout = (options?: UseCheckoutOptions) => {
  const { client } = useApolloClient();
  const config = useRuntimeConfig();
  const jwtClaims = useUserJwtClaims();
  const { channel: currentChannel } = useChannel();

  // useCookie's name argument is not reactive
  const channel = options?.channel ?? currentChannel.value;
  const cookieName = getCheckoutCookieName(channel, jwtClaims.value?.user_id);

  const checkoutIdCookie = useCookie(cookieName, {
    sameSite: "lax",
    secure: config.public.STOREFRONT_URL.startsWith("https"),
    maxAge: CHECKOUT_COOKIE_MAX_AGE,
  });

  const find = async (checkoutId?: string): Promise<CheckoutDetailsFragment | null | undefined> => {
    const res = await findUtil({
      client,
      variables: {
        checkoutId,
      },
    });

    if (res.success) {
      return res.value;
    }
  };

  const findOrCreate = async (
    checkoutId?: string | null
  ): Promise<CheckoutDetailsFragment | null | undefined> => {
    const res = await findOrCreateUtil({
      client,
      variables: {
        channel,
        checkoutId,
      },
    });

    if (res.success) {
      return res.value;
    }
  };

  const create = async (): Promise<CheckoutDetailsFragment | null | undefined> => {
    const res = await createUtil({
      client,
      variables: {
        channel,
        lines: [],
      },
    });

    if (res.success) {
      return res.value;
    }
  };

  const addLine = async ({
    productVariantId,
    quantity,
  }: {
    productVariantId: string;
    quantity: number;
  }): Promise<Result<CheckoutError[] | string, CheckoutCartFragment>> => {
    const res = await addLineUtil({
      client,
      variables: {
        checkoutId: checkoutIdCookie.value,
        productVariantId,
        quantity,
        channel,
      },
    });

    if (!res.success) {
      return res;
    }

    checkoutIdCookie.value = res.value.id;

    return ok(res.value);
  };

  const deleteLines = (lineIds: string | string[]): Promise<void> => {
    return deleteLinesUtil(lineIds, client, checkoutIdCookie.value);
  };

  const updateEmail = async (email: string): Promise<void> => {
    if (!checkoutIdCookie.value) {
      return;
    }
    await client.mutate({
      mutation: CheckoutEmailUpdateDocument,
      variables: {
        id: checkoutIdCookie.value,
        email,
      },
    });
  };

  const setAddress = async (address: AddressInput, type: AddressTypeEnum): Promise<void> => {
    if (!checkoutIdCookie.value) {
      return;
    }

    if (type === AddressTypeEnum.Billing) {
      await client.mutate({
        mutation: CheckoutBillingAddressUpdateDocument,
        variables: {
          checkoutId: checkoutIdCookie.value,
          billingAddress: address,
        },
      });
    } else {
      await client.mutate({
        mutation: CheckoutShippingAddressUpdateDocument,
        variables: {
          checkoutId: checkoutIdCookie.value,
          shippingAddress: address,
        },
      });
    }
  };

  const setShippingAddress = (shippingAddress: AddressInput) => {
    setAddress(shippingAddress, AddressTypeEnum.Shipping);
  };

  const setBillingAddress = (billingAddress: AddressInput) => {
    setAddress(billingAddress, AddressTypeEnum.Billing);
  };

  return {
    checkoutIdCookie,
    create,
    addLine,
    find,
    findOrCreate,
    deleteLines,
    updateEmail,
    setAddress,
    setBillingAddress,
    setShippingAddress,
  };
};
