import { type CheckoutError, CheckoutErrorCode } from "~/src/api/generated/graphql";

enum ErrorType {
  NoCheckoutId = "NO_CHECKOUT_ID",
  NoCheckout = "NO_CHECKOUT",
  Unauthorized = "UNAUTHORIZED",
}

export function isCheckoutError(error: unknown): error is CheckoutError {
  return Object.values(CheckoutErrorCode).includes((error as CheckoutError).code);
}

export { ErrorType };
