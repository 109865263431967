<template>
  <component :is="as">
    <button
      :href="href"
      class="flex w-full items-center rounded-[20px] border px-4 py-3 hover:border-primary"
      v-bind="$attrs"
      @click="onClick(href)"
    >
      <div class="mr-3 flex w-full items-center justify-between">
        <span class="text-16 label">{{ category }}</span>
        <span
          class="label inline-block min-w-[28px] rounded-xl bg-primary px-2 py-1 text-center text-white"
        >
          <Icon v-if="loading" icon="svg-spinners:3-dots-bounce" class="inline-block" />
          <template v-else>
            {{ count }}
          </template>
        </span>
      </div>
      <Icon icon="lucide:chevron-right" class="h-7 w-7 text-primary" />
    </button>
  </component>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";
import type { AsTag } from "radix-vue";

const { as = "li" } = defineProps<{
  category: string;
  href: string;
  count: number;
  as?: AsTag;
  loading?: boolean;
}>();

const router = useRouter();

const onClick = (href: string) => {
  router.push(href);
};

defineOptions({
  inheritAttrs: false,
});
</script>
