import type { GlobalSearchQuery, GlobalSearchQueryVariables } from "~/src/api/generated/graphql";
import { GlobalSearchDocument } from "~/src/api/generated/graphql";
import { useChannel } from "~/src/channel/composables";

export const useGlobalSearch = () => {
  const debouncedLoading = ref(false);
  const { languageCode, channel } = useChannel();

  const { loading: _loading, refetch } = useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    {
      search: "",
      languageCode: languageCode.value,
      channel: channel.value,
    }
  );

  type CategorySearchResult = {
    id: string;
    name: string;
    slug: string;
    productsCount: number;
  };

  const data = ref<CategorySearchResult[]>();

  const search = async (search: string) => {
    if (!search) {
      return;
    }

    const res = await refetch({
      search,
      languageCode: languageCode.value,
      channel: channel.value,
    });

    data.value = res?.data.categories?.edges.map(({ node }) => ({
      id: node.id,
      name: node.translation?.name ?? node.name,
      slug: node.slug,
      productsCount: node.products?.totalCount ?? 0,
    }));
  };

  const _debouncedSearch = useDebounceFn(
    async (searchStr: string) => {
      await search(searchStr);
      debouncedLoading.value = false;
    },
    300,
    { maxWait: 1000 }
  );

  const debouncedSearch = async (searchStr: string) => {
    debouncedLoading.value = true;
    _debouncedSearch(searchStr);
  };

  const loading = computed(() => _loading.value || debouncedLoading.value);

  return {
    search,
    debouncedSearch,
    loading,
    data,
  };
};
