export const fetchGql = async <TQuery, TQueryVariables>({
  query,
  variables,
}: {
  query: string;
  variables: TQueryVariables;
}): Promise<{ data: TQuery }> => {
  const config = useRuntimeConfig();
  const res = await fetch(config.public.SALEOR_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  return await res.json();
};
