import type { CookieOptions } from "#app";
import {
  ACCESS_TOKEN_MAX_AGE,
  REFRESH_TOKEN_MAX_AGE,
  SALEOR_ACCESS_TOKEN_NAME,
  SALEOR_REFRESH_TOKEN_NAME,
} from "~/constants/saleor";

export const useTokenCookies = () => {
  const config = useRuntimeConfig();

  const cookieOptions: Pick<CookieOptions, "sameSite" | "httpOnly" | "secure"> = {
    sameSite: "lax",
    secure: config.public.STOREFRONT_URL.startsWith("https"),
  };

  // TODO: these should be made readonly or even httpOnly conditionally
  const accessTokenCookie = useCookie(SALEOR_ACCESS_TOKEN_NAME, {
    ...cookieOptions,
    maxAge: ACCESS_TOKEN_MAX_AGE,
  });
  const refreshTokenCookie = useCookie(SALEOR_REFRESH_TOKEN_NAME, {
    ...cookieOptions,
    maxAge: REFRESH_TOKEN_MAX_AGE,
  });
  return {
    accessTokenCookie,
    refreshTokenCookie,
  };
};
