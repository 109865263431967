<template>
  <Primitive :as="as" v-bind="props" class="m-auto max-w-7xl px-3 md:px-6">
    <slot />
  </Primitive>
</template>
<script setup lang="ts">
import type { PrimitiveProps } from "radix-vue";
import { Primitive } from "radix-vue";

export type ContentProps = PrimitiveProps & {
  class?: string;
};

const { as = "div", ...props } = defineProps<ContentProps>();
</script>
