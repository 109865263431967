<template>
  <HoverCardRoot v-model:open="cartStore.open">
    <HoverCardTrigger as-child>
      <slot name="trigger" :items-count="itemsCount" :checkout-id="checkoutIdCookie" />
    </HoverCardTrigger>
    <HoverCardPortal>
      <HoverCardContent
        :side-offset="5"
        as-child
        class="z-20 min-w-[300px] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all"
      >
        <CartPreview v-if="!loading" :checkout="checkout" @delete-line="deleteLines" />
        <HoverCardArrow class="fill-white" :width="8" />
      </HoverCardContent>
    </HoverCardPortal>
  </HoverCardRoot>
</template>
<script setup lang="ts">
import { CartPreviewDocument } from "@graphql";
import {
  HoverCardArrow,
  HoverCardContent,
  HoverCardPortal,
  HoverCardRoot,
  HoverCardTrigger,
} from "radix-vue";

import { useCheckout } from "~/src/checkout/composables";
import { cartStore } from "~/stores/cart";

import CartPreview from "./CartPreview.vue";

const { checkoutIdCookie, deleteLines } = useCheckout();

const { result, loading } = useQuery(
  CartPreviewDocument,
  computed(() => ({
    id: checkoutIdCookie.value ?? "",
  })),
  {
    enabled: computed(() => !!checkoutIdCookie.value),
  }
);

const checkout = computed(() => (checkoutIdCookie.value ? result.value?.checkout : undefined));

const itemsCount = computed<number>(
  () =>
    checkout.value?.lines.reduce<number>((acc, line) => {
      acc += line.quantity;
      return acc;
    }, 0) ?? 0
);
</script>
