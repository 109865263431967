import basex from "base-x";

export enum Charset {
  BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  BASE10 = "0123456789",
}

export const baseX = (charset: Charset) => {
  const base = basex(charset);

  const encode = (input: string): string => {
    return base.encode(new TextEncoder().encode(input));
  };

  const decode = (encoded: string): string => {
    return new TextDecoder().decode(base.decode(encoded));
  };

  return {
    encode,
    decode,
  };
};

export const base62 = baseX(Charset.BASE62);
export const base10 = baseX(Charset.BASE10);
